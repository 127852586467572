import { createAction, handleActions } from 'redux-actions';
import memoizeOne from 'memoize-one';

import download from 'downloadjs';
import toast from 'utilities/toast';

export const types = {
  ASSET_LIST_REQUEST: 'app/capitalPlanning/ASSET_LIST_REQUEST',
  ASSET_LIST_RESPONSE: 'app/capitalPlanning/ASSET_LIST_RESPONSE',
  ASSET_DETAILS_REQUEST: 'app/capitalPlanning/ASSET_DETAILS_REQUEST',
  ASSET_DETAILS_RESPONSE: 'app/capitalPlanning/ASSET_DETAILS_RESPONSE',
  TREEMAP_REQUEST: 'app/capitalPlanning/TREEMAP_REQUEST',
  TREEMAP_RESPONSE: 'app/capitalPlanning/TREEMAP_RESPONSE',
  CASH_FLOW_REQUEST: 'app/capitalPlanning/CASH_FLOW_REQUEST',
  CASH_FLOW_RESPONSE: 'app/capitalPlanning/CASH_FLOW_RESPONSE',
  BUDGET_PLANNING_REQUEST: 'app/capitalPlanning/BUDGET_PLANNING_REQUEST',
  BUDGET_PLANNING_RESPONSE: 'app/capitalPlanning/BUDGET_PLANNING_RESPONSE',
  FILTER_OPTS_REQUEST: 'app/capitalPlanning/FILTER_OPTS_REQUEST',
  FILTER_OPTS_RESPONSE: 'app/capitalPlanning/FILTER_OPTS_RESPONSE',
  EQUIPMENT_TYPES_REQUEST: 'app/capitalPlanning/EQUIPMENT_TYPES_REQUEST',
  EQUIPMENT_TYPES_RESPONSE: 'app/capitalPlanning/EQUIPMENT_TYPES_RESPONSE',
  MARK_CASHFLOW_REFRESH: 'app/capitalPlanning/MARK_CASHFLOW_REFRESH',
  METRIC_SCORING_REQUEST: 'app/capitalPlanning/METRIC_SCORING_REQUEST',
  METRIC_SCORING_RESPONSE: 'app/capitalPlanning/METRIC_SCORING_RESPONSE',
  SELECT_EQUIPMENT_TYPES: 'app/capitalPlanning/SELECT_EQUIPMENT_TYPES',
  SELECT_LIFE_INDICATOR: 'app/capitalPlanning/SELECT_LIFE_INDICATOR',
  SELECT_SUBSYSTEMS: 'app/capitalPlanning/SELECT_SUBSYSTEMS',
  UPDATE_DISCOUNT_RATE: 'app/capitalPlanning/UPDATE_DISCOUNT_RATE',
  UPDATE_INFLATION_RATE: 'app/capitalPlanning/UPDATE_INFLATION_RATE',
  UPDATE_EVALUATION_PERIOD: 'app/capitalPlanning/UPDATE_EVALUATION_PERIOD',
  UPDATE_METRIC: 'app/capitalPlanning/UPDATE_METRIC',
  RESET_METRIC: 'app/capitalPlanning/RESET_METRIC',
  UPDATE_METRIC_SORTING: 'app/capitalPlanning/UPDATE_METRIC_SORTING',
  UPDATE_REPLACEMENTS: 'app/capitalPlanning/UPDATE_REPLACEMENTS',

  // Budget Planning Chart Updates to Cap planning state
  ADD_YEAR_TO_BUDGET_PLAN: 'app/budgetPlanning/ADD_YEAR_TO_BUDGET_PLAN',
  REMOVE_YEAR_FROM_BUDGET_PLAN:
    'app/budgetPlanning/REMOVE_YEAR_FROM_BUDGET_PLAN',
  UPDATE_BUDGET_AMOUNT: 'app/budgetPlanning/UPDATE_BUDGET_AMOUNT',
  UPDATE_ASSETS_WITH_BUDGET_YEAR:
    'app/budgetPlanning/UPDATE_ASSETS_WITH_BUDGET_YEAR',
  RESET_BUDGET_PLANNING: 'app/budgetPlanning/RESET_BUDGET_PLANNING',
  REHYDRATE_BUDGET_PLANNING_SCENARIO:
    'app/budgetPlanning/REHYDRATE_BUDGET_PLANNING_SCENARIO',
  SET_BUDGET_OVERRIDE: 'app/capitalPlanning/SET_BUDGET_OVERRIDE',
  RESET_BUDGET_OVERRIDE: 'app/capitalPlanning/RESET_BUDGET_OVERRIDE',
  // PDF
  PDF_CHART_IMAGES_REQUEST: 'app/capitalPlanning/PDF_CHART_IMAGES_REQUEST',
  PDF_CHART_IMAGES_RESPONSE: 'app/capitalPlanning/PDF_CHART_IMAGES_RESPONSE',
  ADD_PDF_IMAGE: 'app/capitalPlanning/ADD_PDF_IMAGE',

  //FCA CSV
  FCA_CSV_DOWNLOAD_REQUEST: 'app/capitalPlanning/FCA_CSV_DOWNLOAD_REQUEST',
  FCA_CSV_DOWNLOAD_RESPONSE: 'app/capitalPlanning/FCA_CSV_DOWNLOAD_RESPONSE',

  FCA_CSV_UPLOAD_REQUEST: 'app/capitalPlanning/FCA_CSV_UPLOAD_REQUEST',
  FCA_CSV_UPLOAD_RESPONSE: 'app/capitalPlanning/FCA_CSV_UPLOAD_RESPONSE',

  FCA_UPLOAD_REQUEST: 'app/capitalPlanning/FCA_UPLOAD_REQUEST',
  FCA_UPLOAD_RESPONSE: 'app/capitalPlanning/FCA_UPLOAD_RESPONSE,',

  FCA_UPLOAD_RESULTS_REQUEST: 'app/capitalPlanning/FCA_UPLOAD_RESULTS_REQUEST',
  FCA_UPLOAD_RESULTS_RESPONSE:
    'app/capitalPlanning/FCA_UPLOAD_RESULTS_RESPONSE',

  FCA_EXPORT_REQUEST: 'app/capitalPlanning/FCA_EXPORT_REQUEST',
  FCA_EXPORT_RESPONSE: 'app/capitalPlanning/FCA_EXPORT_RESPONSE',

  FCA_EXPORT_RESULTS_REQUEST: 'app/capitalPlanning/FCA_EXPORT_RESULTS_REQUEST',
  FCA_EXPORT_RESULTS_RESPONSE:
    'app/capitalPlanning/FCA_EXPORT_RESULTS_RESPONSE',

  FCA_BLOB_REQUEST: 'app/capitalPlanning/FCA_BLOB_REQUEST',
  FCA_BLOB_RESPONSE: 'app/capitalPlanning/FCA_BLOB_RESPONSE',
};

export const actions = {
  assetListRequest: createAction(types.ASSET_LIST_REQUEST),
  assetListResponse: createAction(types.ASSET_LIST_RESPONSE),
  assetDetailsRequest: createAction(types.ASSET_DETAILS_REQUEST),
  assetDetailsResponse: createAction(types.ASSET_DETAILS_RESPONSE),
  treemapRequest: createAction(types.TREEMAP_REQUEST),
  treemapResponse: createAction(types.TREEMAP_RESPONSE),
  cashFlowRequest: createAction(types.CASH_FLOW_REQUEST),
  cashFlowResponse: createAction(types.CASH_FLOW_RESPONSE),
  budgetPlanningRequest: createAction(types.BUDGET_PLANNING_REQUEST),
  budgetPlanningResponse: createAction(types.BUDGET_PLANNING_RESPONSE),
  filterOptsRequest: createAction(types.FILTER_OPTS_REQUEST),
  filterOptsResponse: createAction(types.FILTER_OPTS_RESPONSE),
  equipmentTypesRequest: createAction(types.EQUIPMENT_TYPES_REQUEST),
  equipmentTypesResponse: createAction(types.EQUIPMENT_TYPES_RESPONSE),
  markCashFlowRefresh: createAction(types.MARK_CASHFLOW_REFRESH),
  metricScoringRequest: createAction(types.METRIC_SCORING_REQUEST),
  metricScoringResponse: createAction(types.METRIC_SCORING_RESPONSE),
  selectEquipmentTypes: createAction(types.SELECT_EQUIPMENT_TYPES),
  selectLifeIndicator: createAction(types.SELECT_LIFE_INDICATOR),
  selectSubsystems: createAction(types.SELECT_SUBSYSTEMS),
  updateDiscountRate: createAction(types.UPDATE_DISCOUNT_RATE),
  updateInflationRate: createAction(types.UPDATE_INFLATION_RATE),
  updateEvaluationPeriod: createAction(types.UPDATE_EVALUATION_PERIOD),
  updateMetric: createAction(types.UPDATE_METRIC),
  resetMetric: createAction(types.RESET_METRIC),
  updateMetricSorting: createAction(types.UPDATE_METRIC_SORTING),
  updateReplacements: createAction(types.UPDATE_REPLACEMENTS),
  // budget planning
  addYearToBudgetPlan: createAction(types.ADD_YEAR_TO_BUDGET_PLAN),
  removeYearFromBudgetPlan: createAction(types.REMOVE_YEAR_FROM_BUDGET_PLAN),
  updateBudgetAmount: createAction(types.UPDATE_BUDGET_AMOUNT),
  resetBudgetPlanning: createAction(types.RESET_BUDGET_PLANNING),
  rehydrateBudgetPlanningScenario: createAction(
    types.REHYDRATE_BUDGET_PLANNING_SCENARIO
  ),
  //-- Budget Override --
  setBudgetOverride: createAction(types.SET_BUDGET_OVERRIDE),
  resetBudgetOverride: createAction(types.RESET_BUDGET_OVERRIDE),
  // PDF
  PDFChartImagesRequest: createAction(types.PDF_CHART_IMAGES_REQUEST),
  PDFChartImagesResponse: createAction(types.PDF_CHART_IMAGES_RESPONSE),
  addPDFImage: createAction(types.ADD_PDF_IMAGE),

  // FCA
  FCATemplateDownloadRequest: createAction(types.FCA_CSV_DOWNLOAD_REQUEST),
  FCATemplateDownloadResponse: createAction(types.FCA_CSV_DOWNLOAD_RESPONSE),

  FCAUploadResultsRequest: createAction(types.FCA_UPLOAD_RESULTS_REQUEST),
  FCAUploadResultsResponse: createAction(types.FCA_UPLOAD_RESULTS_RESPONSE),

  FCAUploadRequest: createAction(types.FCA_UPLOAD_REQUEST),
  FCAUploadResponse: createAction(types.FCA_UPLOAD_RESPONSE),

  FCAExportRequest: createAction(types.FCA_EXPORT_REQUEST),
  FCAExportResponse: createAction(types.FCA_EXPORT_RESPONSE),

  FCAExportResultsRequest: createAction(types.FCA_EXPORT_RESULTS_REQUEST),
  FCAExportResultsResponse: createAction(types.FCA_EXPORT_RESULTS_RESPONSE),

  FCABlobRequest: createAction(types.FCA_BLOB_REQUEST),
  FCABlobResponse: createAction(types.FCA_BLOB_RESPONSE),
};

const thisYear = () => new Date().getFullYear();

const defaultState = {
  assets: [],
  assetListStats: null,
  assetDetails: null,
  assetDetailsLoading: false,
  bucketHexColors: [],
  budgetOverride: {},
  cashFlow: null,
  discountRate: '0', // in percent
  evaluationPeriod: '30',
  inflationRate: '0', // in percent
  filterOpts: null,
  isRefreshing: false,
  isRefreshingCashFlow: false,
  isRefreshingAssetList: false,
  metricFilters: [],
  metricScores: [],
  metricScoresLoading: false,
  metricSorting: {
    primary: 0, // TODO: really shouldn't use 0's for these as they will return false.
    secondary: 0, // TODO: really shouldn't use 0's for these as they will return false.
    sortBy: 0, // TODO: really shouldn't use 0's for these as they will return false. see `groupBy`
    groupByCategory: 1,
    focusId: null,
    focusCoords: null,
    selectedId: null,
    selectedEquipmentId: null,
    showFocusTooltip: false,
    buildingZoomId: null,
  },
  npv: 0,
  replacements: [1, 2, 3],
  selectedEquipmentTypes: [],
  selectedSubsystems: [],
  useObserved: true,
  //  budgetplanning
  yearsInBudgetPlan: [
    { year: thisYear(), budget: null, remainder: 0, requiredAssets: [] },
  ],
  needAssetUpdateWithBudgetYear: false,
  // pdf
  needPDFChartImage: false,
  isRefreshingPDF: false,
  PDFImages: {},

  // fca
  templateFileDownloading: false,
  dataExportDownloading: false,
  dataExportResultsDownloading: false,
  dataUploadResultsDownloading: false,
  fcaFilesUploading: false,
  fcaBlobDownloading: false,

  fcaUploadResults: [],
  fcaExportResults: [],

  treemapRefreshing: false,
  treemapData: {},
  isRefreshingBudgetPlanning: false,
  budgetPlanningData: [],
  assetBudgetReplacementYears: {},
  mapData: [],
  treemapOverview: {},
};

// Action Creators /////////////////////////////////////////////////////////////
const fetchAssetList_raw = (
  siteId,
  buildingIds,
  equipmentTypeIds,
  metricFilters,
  useObserved,
  assetScoreFilter,
  inflationRate,
  aliasId = null,
  pageSize = null,
  currentPage = null,
  search = '',
  orderBy = 'TotalScore',
  orderDesc = false
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.assetListRequest());
  try {
    const { data } = await api.capitalPlanning.assetList(
      siteId,
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      useObserved,
      aliasId,
      pageSize,
      currentPage,
      search,
      orderBy,
      orderDesc,
      assetScoreFilter,
      _getState().capitalPlanning.assetBudgetReplacementYears ?? {},
      inflationRate
    );
    return dispatch(actions.assetListResponse(data));
  } catch (err) {
    return dispatch(actions.assetListResponse(err));
  }
};
//-- Memoized Version --
export const fetchAssetList = memoizeOne(fetchAssetList_raw);

export const fetchAssetDetails = (
  assetId,
  metricFilters,
  useObserved
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.assetDetailsRequest());
  try {
    const { data } = await api.capitalPlanning.assetDetails(
      assetId,
      metricFilters,
      useObserved
    );
    return dispatch(actions.assetDetailsResponse(data));
  } catch (err) {
    return dispatch(actions.assetDetailsResponse(err));
  }
};

/**
 * Fetch the chart data for cash flows based on the provided options.
 * @param {number} siteId
 * @param {number[]} buildingIds
 * @param {number[]} equipmentTypeIds
 * @param {Object[]} metricFilters
 * @param {boolean} useObserved
 * @param {number[]} replacements
 * @param {number} discountRate
 * @param {number} inflationRate
 * @param {Object} assetScoreFilter
 * @param {number=} aliasId
 */
const fetchCashFlowChart_raw = (
  siteId,
  buildingIds,
  equipmentTypeIds,
  metricFilters,
  useObserved,
  replacements,
  discountRate,
  inflationRate,
  assetScoreFilter,
  evaluationPeriod,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.cashFlowRequest());
  try {
    const { data } = await api.capitalPlanning.cashFlowChart(
      siteId,
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      useObserved,
      replacements,
      discountRate,
      inflationRate,
      assetScoreFilter,
      evaluationPeriod,
      aliasId
    );
    return dispatch(actions.cashFlowResponse(data));
  } catch (err) {
    return dispatch(actions.cashFlowResponse(err));
  }
};
//-- Memoized --
export const fetchCashFlowChart = memoizeOne(fetchCashFlowChart_raw);

/**
 * Request the API to fetch the equipment types for the selected subsystems in
 * the selected buildings.
 * @param {number[]} buildingIds
 * @param {number[]} subsystemIds
 */
const fetchEquipmentTypes_raw = (buildingIds, subsystemIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.equipmentTypesRequest());
  try {
    const { data } = await api.capitalPlanning.equipmentTypes(
      buildingIds,
      subsystemIds
    );
    return dispatch(actions.equipmentTypesResponse(data));
  } catch (err) {
    return dispatch(actions.equipmentTypesResponse(err));
  }
};
//-- memoized --
export const fetchEquipmentTypes = memoizeOne(fetchEquipmentTypes_raw);

/**
 * Request the API to fetch filter option data for the matching buildings in
 * the matching site.
 * @param {number} siteId - The site ID number (acquired from buildings overview).
 * @param {number[]} buildingIds - An array of IDs for the buildings to get data on.
 * @param {Object} performanceOverrides
 * @param {number} evaluationPeriod
 * @param {Object[]} metricFilters
 */
const fetchFilterOptions_raw = (
  siteId,
  buildingIds,
  performanceOverrides,
  evaluationPeriod,
  metricFilters
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.filterOptsRequest());
  try {
    const { data } = await api.capitalPlanning.filterOptions(
      siteId,
      buildingIds,
      evaluationPeriod,
      metricFilters
    );
    return dispatch(
      actions.filterOptsResponse({
        ...data,
        performanceOverrides: performanceOverrides,
      })
    );
  } catch (err) {
    return dispatch(actions.filterOptsResponse(err));
  }
};
//-- memoized --
export const fetchFilterOptions = memoizeOne(fetchFilterOptions_raw);

/**
 * Fetch the treemap data object.
 * @param {number} siteId - Site identifier
 * @param {number[]} buildingIds - List of building identifiers
 * @param {number[]} equipmentTypeIds - List of equipment type identifiers
 * @param {Object[]} metricFilters - List of FCA metric filter objects
 * @param {boolean} useObserved - Observed/Industry remaining life toggle
 * @param {number} primaryMetricId - Size metric identifier
 * @param {number} secondaryMetricId - Color metric identifier
 * @param {string[]} colors - List of hex color buckets to use
 * @param {boolean} useAbsoluteScale - Relative/Absolute scale toggle
 * @param {string} grouping - What the root level of the treemap represents (building, system, subsystem, equipment)
 * @param {number} depth - Levels to drill into each of the root cells
 * @param {Object} assetScoreFilter - Min/Max asset score filter
 * @param {boolean} sortByPrimary - Primary/Secondary sorting
 * @param {number=} aliasId - Optional building name alias set identifier
 */
export const fetchTreemap = (
  siteId,
  buildingIds,
  equipmentTypeIds,
  metricFilters,
  useObserved,
  primaryMetricId,
  secondaryMetricId,
  colors,
  useAbsoluteScale,
  grouping,
  depth,
  assetScoreFilter,
  sortByPrimary,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.treemapRequest());
  try {
    const { data } = await api.capitalPlanning.treemap(
      siteId,
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      useObserved,
      primaryMetricId,
      secondaryMetricId,
      colors,
      useAbsoluteScale,
      assetScoreFilter,
      grouping,
      depth,
      sortByPrimary,
      aliasId
    );
    return dispatch(actions.treemapResponse(data));
  } catch (err) {
    return dispatch(actions.treemapResponse(err));
  }
};

export const fetchBudgetPlanning = (
  siteId,
  buildingIds,
  equipmentTypeIds,
  metricFilters,
  assetScoreFilter,
  useObserved
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.budgetPlanningRequest());

  let yearAllocations = [];

  let overrides = Object.entries(
    _getState().capitalPlanning.budgetOverride
  ).map(entry => ({
    id: entry[0],
    ...entry[1],
  }));
  let excludedEquipmentIds = [];
  if (overrides) {
    let _excludedOverrides = overrides.filter(x => x.year === -1);
    excludedEquipmentIds = _excludedOverrides.map(x => x.equipmentId);
  }

  _getState().capitalPlanning.yearsInBudgetPlan.forEach(planYear => {
    let planYearOverrides = {
      budget: planYear.budget,
      remainder: planYear.remainder,
      requiredAssets: overrides
        .filter(x => x.year === planYear.year)
        .map(y => y.equipmentId),
      year: planYear.year,
    };

    yearAllocations.push(planYearOverrides);
  });

  try {
    const { data } = await api.budgetplanning.chart(
      siteId,
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      assetScoreFilter,
      useObserved,
      yearAllocations,
      excludedEquipmentIds
    );
    return dispatch(actions.budgetPlanningResponse(data));
  } catch (err) {
    return dispatch(actions.budgetPlanningResponse(err));
  }
};

/**
 * Request the API to fetch metric scores for the capital planning section, but
 * only using the core filters of site/buildings, and equipment type ids.
 * @param {*} siteId
 * @param {*} buildingIds
 * @param {*} equipmentTypeIds
 */
const fetchMetricScoresAll_raw = (
  siteId,
  buildingIds,
  equipmentTypeIds,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.metricScoringRequest());
  try {
    const { data } = await api.capitalPlanning.metricScoring(
      siteId,
      buildingIds,
      equipmentTypeIds,
      null,
      null,
      null,
      null,
      aliasId
    );
    return dispatch(actions.metricScoringResponse(data));
  } catch (err) {
    return dispatch(actions.metricScoringResponse(err));
  }
};
//-- Memoize --
export const fetchMetricScoresAll = memoizeOne(fetchMetricScoresAll_raw);

export const markCashFlowRefresh = () => async (dispatch, _getState) => {
  dispatch(actions.markCashFlowRefresh());
};

/**
 * Update the store with a list of equipment type ids to use in filtering the
 * capital planning data.
 * @param {number[]} equipmentTypeIds
 */
export const selectEquipmentTypes = equipmentTypeIds => async (
  dispatch,
  _getState
) => {
  dispatch(
    actions.selectEquipmentTypes({ equipmentTypeIds: [...equipmentTypeIds] })
  );
};

/**
 * Update the store with the type of life indicator to use, observed or industrial,
 * in the capital planning data.
 * @param {boolean} useObserved
 */
export const selectLifeIndicator = useObserved => async (
  dispatch,
  _getState
) => {
  saveFilter('useObserved', useObserved);
  dispatch(actions.selectLifeIndicator({ useObserved: useObserved }));
};

export const updateAssetScore = metric => async (dispatch, _getState) => {
  saveFilter('assetScore', metric);
};

/**
 * Update the currently selected subsystems in the capital planning dropdown.
 * @param {number[]} subsystemIds - The subsystem ids to mark as selected.
 */
export const selectSubsystems = subsystemIds => async (dispatch, _getState) => {
  dispatch(actions.selectSubsystems({ subsystemIds: subsystemIds }));
};

/**
 * Update the discount rate for the capital planning cash flow.
 * @param {number} rate
 */
export const updateDiscountRate = rate => async (dispatch, _getState) => {
  saveFilter('discountRate', rate);
  dispatch(actions.updateDiscountRate({ rate: rate }));
};

/**
 * Update the inflation rates for the capital planning cash flow.
 * @param {Object} rate
 */
export const updateInflationRate = rate => async (dispatch, _getState) => {
  saveFilter('inflationRate', rate);
  dispatch(actions.updateInflationRate({ rate: rate }));
};

/**
 * Update evaluation period for the capital planning cash flow
 * @param {Object} period
 */
export const updateEvaluationPeriod = period => async (dispatch, _getState) => {
  saveFilter('evaluationPeriod', period);
  dispatch(actions.updateEvaluationPeriod({ period: period }));
};

/**
 * Update the the selected values for a metric filter.
 * @param {{metricId: number, maxValue: number, minValue: number, weight: number}} metric
 */
export const updateMetric = metric => async (dispatch, _getState) => {
  saveMetric(metric);
  dispatch(actions.updateMetric({ metric: metric }));
};

export const resetMetric = () => dispatch => {
  dispatch(actions.resetMetric());
};

// SAVE METRIC = = = = = = = = = = = = = = = = = = = =
// SAVE METRIC = = = = = = = = = = = = = = = = = = = =
// SAVE METRIC = = = = = = = = = = = = = = = = = = = =

const saveMetric = metric => {
  let _localMetric;
  let _metrics = JSON.parse(localStorage.getItem('sideBarMetrics')) || [];
  if (_metrics && _metrics.length) {
    _localMetric = _metrics.find(_m => _m.metricId === metric.metricId);
    if (_localMetric)
      _metrics = _metrics.filter(_m => _m.metricId !== metric.metricId);
  }

  localStorage.setItem('sideBarMetrics', JSON.stringify([..._metrics, metric]));
};

// SAVE FILTER = = = = = = = = = = = = = = = = = = = =
// SAVE FILTER = = = = = = = = = = = = = = = = = = = =
// SAVE FILTER = = = = = = = = = = = = = = = = = = = =

const saveFilter = (title, filter) => {
  let _localFilter;
  let _filters = JSON.parse(localStorage.getItem('sideBarFilters')) || {};
  if (_filters && Object.keys(_filters).length) {
    _localFilter = _filters[title];
    if (_localFilter) delete _filters[title];
  }
  _filters[title] = filter;

  localStorage.setItem('sideBarFilters', JSON.stringify(_filters));
};

export const updateMetricSorting = sorting => async (dispatch, _getState) => {
  dispatch(actions.updateMetricSorting({ sorting: sorting }));
};

/**
 * Update the selected replacements
 * @param {number[]} replacements
 */
export const updateReplacements = replacements => async (
  dispatch,
  _getState,
  { api }
) => {
  if (replacements !== null && replacements !== undefined) {
    saveFilter('replacements', replacements);
    dispatch(actions.updateReplacements({ replacements: replacements }));
  }
};

export const addYearToBudgetPlan = () => (dispatch, getState) => {
  const currentYearsInBudgetPlan = getState().capitalPlanning.yearsInBudgetPlan;
  const nextNewYear = [
    {
      // Grab the last year in the array and add one
      year: currentYearsInBudgetPlan.slice(-1)[0].year + 1,
      budget: null,
      remainder: 0,
      requiredAssets: [],
    },
  ];
  const allYearsInBudgetPlan = currentYearsInBudgetPlan.concat(nextNewYear);
  dispatch(actions.addYearToBudgetPlan(allYearsInBudgetPlan));
};

export const removeYearFromBudgetPlan = scoreWeightedAssets => (
  dispatch,
  getState
) => {
  // remove last year in budget plan
  const yearsInBudgetPlan = getState().capitalPlanning.yearsInBudgetPlan;
  const lastYear = yearsInBudgetPlan[yearsInBudgetPlan.length - 1]?.year;
  const updatedYearsInBudgetPlan = yearsInBudgetPlan.slice(0, -1);
  const updatedBudgetPlanningData = getState().capitalPlanning.budgetPlanningData.slice(
    0,
    -1
  );
  // remove the last year's data in asset list's replacement year column
  const updatedAssetBudgetReplacementYears = Object.assign(
    {},
    getState().capitalPlanning.assetBudgetReplacementYears
  );
  Object.entries(updatedAssetBudgetReplacementYears).forEach(([key, value]) => {
    if (value === lastYear) {
      delete updatedAssetBudgetReplacementYears[key];
    }
  });
  dispatch(
    actions.removeYearFromBudgetPlan({
      yearsInBudgetPlan: updatedYearsInBudgetPlan,
      budgetPlanningData: updatedBudgetPlanningData,
      assetBudgetReplacementYears: updatedAssetBudgetReplacementYears,
    })
  );
};

export const updateBudgetAmount = payload => (dispatch, getState) => {
  // Grab the year associated with the updated budget, replace with new value
  const updatedBudgetYear = getState().capitalPlanning.yearsInBudgetPlan.map(
    item => {
      if (item.year === Number(payload.year)) {
        return {
          ...item,
          budget: Number(payload.budget),
        };
      }
      return item;
    }
  );
  dispatch(actions.updateBudgetAmount(updatedBudgetYear));
};

export const resetBudgetPlanning = () => dispatch => {
  dispatch(actions.resetBudgetPlanning());
};

export const rehydrateBudgetPlanningScenario = () => dispatch => {
  // since this is called by the 'budgetPlanningScenarios' reducer
  // the { payload } from that dispatch comes through 'dispatch' behind
  // the scenes since it's handled by a async thunk.
  // confusing! - uncomment redux logger to see what's happening.
  dispatch(actions.rehydrateBudgetPlanningScenario());
};

export const PDFChartImagesRequest = () => dispatch => {
  dispatch(actions.PDFChartImagesRequest());
};

export const PDFChartImagesResponse = () => dispatch => {
  dispatch(actions.PDFChartImagesResponse());
};

export const addPDFImage = imageData => dispatch => {
  dispatch(actions.addPDFImage(imageData));
};

//-- budget override --
export const setBudgetOverride = newBudgetOverride => dispatch => {
  dispatch(actions.setBudgetOverride(newBudgetOverride));
};

export const resetBudgetOverride = () => dispatch => {
  dispatch(actions.resetBudgetOverride());
};

// -- FCA --
export const fetchFCATemplate = (siteId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.FCATemplateDownloadRequest());
  try {
    const { data } = await api.capitalPlanning.fcaTemplateCsv(
      siteId,
      buildingIds
    );
    let mimeType = 'application/binary';
    var bin = atob(data);
    var ab = stringToArrayBuffer(bin);
    let blob = new Blob([ab], { type: mimeType });
    let filename = `${siteId}-FCATemplate.xlsx`;
    download(blob, filename);
    return dispatch(actions.FCATemplateDownloadResponse(data));
  } catch (err) {
    return dispatch(actions.FCATemplateDownloadResponse(err));
  }
};

const stringToArrayBuffer = s => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const fetchFCAExport = (siteId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.FCAExportRequest());
  try {
    const { data } = await api.capitalPlanning.fcaExportDataXlsx(
      siteId,
      buildingIds
    );
    toast.success(
      'FCA Export started successfully. Please check the Download tab for the progress.'
    );
    return dispatch(actions.FCAExportResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(actions.FCAExportResponse(err));
  }
};

export const fetchFCAExportResults = siteId => async (
  dispatch,
  _getstate,
  { api }
) => {
  dispatch(actions.FCAExportResultsRequest());
  try {
    const { data } = await api.capitalPlanning.fcaExportResults(siteId);
    return dispatch(actions.FCAExportResultsResponse(data));
  } catch (err) {
    return dispatch(actions.FCAExportResultsResponse(err));
  }
};

export const fetchFCABlob = (siteId, fileName, isExcel) => async (
  dispatch,
  _getstate,
  { api }
) => {
  dispatch(actions.FCABlobRequest());
  try {
    const { data } = await api.capitalPlanning.fcaBlob(siteId, fileName);

    let _fileName = `fca/${siteId}/${fileName}`;
    download(data, _fileName);
    return dispatch(actions.FCAUploadResponse(data));
  } catch (err) {
    return dispatch(actions.FCAUploadResponse(err));
  }
};

export const fetchFCAXlsx = (siteId, filename) => async (
  dispatch,
  _getstate,
  { api }
) => {
  dispatch(actions.FCABlobRequest());
  try {
    const { data } = await api.capitalPlanning.getFCABlobFile(siteId, filename);
    download(data, `fca/exports/${siteId}/${filename}`);
    return dispatch(actions.FCABlobResponse(data));
  } catch (err) {
    return dispatch(actions.FCABlobResponse(err));
  }
};

export const uploadFCAFiles = (siteId, files, validateOnly = false) => async (
  dispatch,
  _getstate,
  { api }
) => {
  dispatch(actions.FCAUploadRequest());
  try {
    const { data } = await api.capitalPlanning.uploadFcaFiles(
      siteId,
      files,
      validateOnly.toString()
    );
    return dispatch(actions.FCAUploadResponse(data));
  } catch (err) {
    return dispatch(actions.FCAUploadResponse(err));
  }
};

export const fetchFCAUploadResults = siteId => async (
  dispatch,
  _getstate,
  { api }
) => {
  dispatch(actions.FCAUploadResultsRequest());
  try {
    const { data } = await api.capitalPlanning.fcaUploadResults(siteId);
    return dispatch(actions.FCAUploadResultsResponse(data));
  } catch (err) {
    return dispatch(actions.FCAUploadResultsResponse(err));
  }
};

// Action Handler //////////////////////////////////////////////////////////////

export default handleActions(
  {
    // asset list //////////////////////////////////////////////////////////////

    [actions.assetListRequest]: {
      next: state => ({
        ...state,
        isRefreshingAssetList: true,
      }),
    },

    [actions.assetListResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          assets: payload.data,
          assetListStats: payload.stats,
          isRefreshingAssetList: false,
          needAssetUpdateWithBudgetYear: true,
        };
      },
    },

    [actions.assetDetailsRequest]: {
      next: state => ({
        ...state,
        assetDetailsLoading: true,
        assetDetails: null,
      }),
    },

    [actions.assetDetailsResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          assetDetails: payload,
          assetDetailsLoading: false,
        };
      },
    },

    // cash flow chart /////////////////////////////////////////////////////////

    [actions.cashFlowRequest]: {
      next: state => ({
        ...state,
        isRefreshingCashFlow: true,
      }),
    },

    [actions.cashFlowResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          cashFlow: payload,
          npv: payload.netPresentValue,
          isRefreshingCashFlow: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingCashFlow: false,
      }),
    },

    [actions.markCashFlowRefresh]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          isRefreshingCashFlow: true,
        };
      },
    },

    [actions.updateDiscountRate]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          discountRate: payload.rate,
        };
      },
    },

    [actions.updateInflationRate]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          inflationRate: payload.rate,
        };
      },
    },

    [actions.updateEvaluationPeriod]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          evaluationPeriod: payload.period,
        };
      },
    },

    // filterOptions ///////////////////////////////////////////////////////////

    [actions.filterOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.filterOptsResponse]: {
      next: (state, { payload }) => {
        const performanceOverrides = payload?.performanceOverrides ?? false;

        // Select just the first 10 subsystem if performanceOverrides is true
        const selectedSubsystems =
          performanceOverrides === true
            ? payload.subSystems.slice(0, 10)
            : payload.subSystems;

        // Determine all equipment types of those subsystems.
        const selectedEquipmentTypeIds = selectedSubsystems
          .map(x => x.equipmentTypeIds)
          .flat();

        // Filter equipment types to ensure they are children of the subsystem selection.
        const selectedEquipmentTypes = payload.equipmentTypes.filter(x =>
          selectedEquipmentTypeIds.includes(x.id)
        );

        // Create a lookup object for metric weights. If it doesn't exist, default to 100.
        const weightLookup = state?.metricFilters.reduce((acc, item) => {
          acc[item.metricId] = item.weight;
          return acc;
        }, {});

        const metricFilters = payload.metricFilters.map(mf => {
          return {
            metricId: mf.metricId,
            maxValue: mf.maxValue,
            minValue: mf.minValue,
            weight: weightLookup[mf.metricId] || 100,
          };
        });

        return {
          ...state,
          filterOpts: {
            ...payload,
            // Overwrite equipment type options as it is a subset of the original response.
            equipmentTypes: selectedEquipmentTypes,
          },
          isRefreshing: false,
          metricFilters: metricFilters,
          selectedEquipmentTypes: selectedEquipmentTypes.map(x => x.id),
          selectedSubsystems: selectedSubsystems.map(x => x.id),
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // equipmentTypes //////////////////////////////////////////////////////////

    [actions.equipmentTypesRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.equipmentTypesResponse]: {
      next: (_state, { payload }) => {
        const _equipmentTypeIds = payload.map(et => {
          return et.id;
        });
        const _filterOpts = JSON.parse(JSON.stringify(_state.filterOpts));
        _filterOpts.equipmentTypes = payload;
        return {
          ..._state,
          filterOpts: _filterOpts,
          isRefreshing: false,
          selectedEquipmentTypes: _equipmentTypeIds,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.selectEquipmentTypes]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          selectedEquipmentTypes: payload.equipmentTypeIds,
        };
      },
    },

    // subSystems //////////////////////////////////////////////////////////////

    [actions.selectSubsystems]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          selectedSubsystems: payload.subsystemIds,
        };
      },
    },

    // metric filters //////////////////////////////////////////////////////////

    [actions.updateMetric]: {
      next: (_state, { payload }) => {
        let _metricFilters = JSON.parse(JSON.stringify(_state.metricFilters));
        _metricFilters.forEach((filter, index) => {
          if (filter.metricId === payload.metric.metricId) {
            _metricFilters[index] = payload.metric;
          }
        });
        return {
          ..._state,
          metricFilters: _metricFilters,
        };
      },
    },

    [actions.resetMetric]: {
      next: state => {
        let _metricFilters = JSON.parse(JSON.stringify(state.metricFilters));
        const metricFilters = _metricFilters.map(mf => {
          return {
            metricId: mf.metricId,
            maxValue: mf.maxValue,
            minValue: mf.minValue,
            weight: 100,
          };
        });
        return {
          ...state,
          metricFilters: metricFilters,
        };
      },
    },

    [actions.updateReplacements]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          replacements: payload.replacements,
        };
      },
    },

    // metric scores ///////////////////////////////////////////////////////////

    [actions.metricScoringRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        metricScoresLoading: true,
      }),
    },

    [actions.metricScoringResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          bucketHexColors: payload.bucketHexColors,
          metricScores: payload.metricScores,
          isRefreshing: false,
          metricScoresLoading: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
        metricScoresLoading: false,
      }),
    },

    [actions.treemapRequest]: {
      next: state => ({
        ...state,
        treemapRefreshing: true,
      }),
    },

    [actions.treemapResponse]: {
      next: (_state, { payload }) => {
        if (
          payload.chart?.children.length > 0 &&
          payload.chart.children[0].pathKey.type === 'building'
        ) {
          return {
            ..._state,
            treemapData: payload.chart,
            mapData: payload.chart,
            treemapOverview: payload.overview,
            treemapRefreshing: false,
          };
        }

        return {
          ..._state,
          treemapData: payload.chart,
          treemapOverview: payload.overview,
          treemapRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        treemapRefreshing: false,
      }),
    },

    [actions.budgetPlanningRequest]: {
      next: state => ({
        ...state,
        isRefreshingBudgetPlanning: true,
      }),
    },

    [actions.budgetPlanningResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          budgetPlanningData: payload.chart,
          assetBudgetReplacementYears: payload.assets,
          isRefreshingBudgetPlanning: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingBudgetPlanning: false,
      }),
    },

    [actions.updateMetricSorting]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          metricSorting: payload.sorting,
        };
      },
    },

    // useObserved /////////////////////////////////////////////////////////////
    [actions.selectLifeIndicator]: {
      next: (_state, { payload }) => ({
        ..._state,
        useObserved: payload.useObserved,
      }),
    },

    // BUDGET PLANNING ////////////////////////////////////////////////////////
    [actions.addYearToBudgetPlan]: {
      next: (state, { payload }) => {
        return {
          ...state,
          yearsInBudgetPlan: payload,
        };
      },
    },
    [actions.removeYearFromBudgetPlan]: {
      next: (state, { payload }) => {
        return {
          ...state,
          yearsInBudgetPlan: payload.yearsInBudgetPlan,
          budgetPlanningData: payload.budgetPlanningData,
          assetBudgetReplacementYears: payload.assetBudgetReplacementYears,
        };
      },
    },

    [actions.rehydrateBudgetPlanningScenario]: {
      next: (state, { payload }) => {
        return {
          ...state,
          budgetOverride: payload.budgetOverrides,
          yearsInBudgetPlan: payload.allocations,
          discountRate: payload.options.discountRate,
          inflationRate: payload.options.inflationRate,
          assetBudgetReplacementYears: payload.assetBudgetReplacementYears,
          evaluationPeriod: payload.options.evaluationPeriod,
          metricFilters: payload.options.metricFilters,
        };
      },
    },

    [actions.updateBudgetAmount]: {
      next: (state, { payload }) => {
        return {
          ...state,
          yearsInBudgetPlan: payload,
        };
      },
    },

    [actions.resetBudgetPlanning]: {
      next: state => {
        const reset = [
          {
            year: state.yearsInBudgetPlan[0].year,
            budget: null,
            remainder: 0,
            assets: [],
          },
        ];
        return {
          ...state,
          yearsInBudgetPlan: reset,
          budgetPlanningData: [],
          assetBudgetReplacementYears: {},
        };
      },
    },

    [actions.setBudgetOverride]: {
      next: (state, { payload }) => {
        return {
          ...state,
          budgetOverride: payload,
        };
      },
    },

    [actions.resetBudgetOverride]: {
      next: state => {
        return {
          ...state,
          budgetOverride: {},
        };
      },
    },
    [actions.FCATemplateDownloadRequest]: {
      next: state => ({
        ...state,
        templateFileDownloading: true,
      }),
    },
    [actions.FCATemplateDownloadResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        templateFileDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        templateFileDownloading: false,
      }),
    },
    [actions.FCAExportRequest]: {
      next: state => ({
        ...state,
        dataExportDownloading: true,
      }),
    },
    [actions.FCAExportResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        dataExportDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        dataExportDownloading: false,
      }),
    },
    [actions.FCAExportResultsRequest]: {
      next: state => ({
        ...state,
        dataExportResultsDownloading: true,
      }),
    },
    [actions.FCAExportResultsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fcaExportResults: payload,
        dataExportResultsDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        dataExportResultsDownloading: false,
      }),
    },
    [actions.FCAUploadRequest]: {
      next: state => ({
        ...state,
        fcaFilesUploading: true,
      }),
    },
    [actions.FCAUploadResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fcaFilesUploading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        fcaFilesUploading: false,
      }),
    },
    [actions.FCAUploadResultsRequest]: {
      next: state => ({
        ...state,
        dataUploadResultsDownloading: true,
      }),
    },
    [actions.FCAUploadResultsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fcaUploadResults: payload?.data?.results,
        dataUploadResultsDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        dataUploadResultsDownloading: false,
      }),
    },
    [actions.FCABlobRequest]: {
      next: state => ({
        ...state,
        fcaBlobDownloading: true,
      }),
    },
    [actions.FCABlobResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fcaBlobDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        fcaBlobDownloading: false,
      }),
    },
    //--- PDF ---
    [actions.PDFChartImagesRequest]: {
      next: state => {
        return {
          ...state,
          needPDFChartImage: true,
          isRefreshingPDF: true,
        };
      },
    },
    [actions.PDFChartImagesResponse]: {
      next: state => {
        return {
          ...state,
          needPDFChartImage: false,
          isRefreshingPDF: false,
          PDFImages: {},
        };
      },
    },
    [actions.addPDFImage]: {
      next: (state, { payload }) => {
        return {
          ...state,
          PDFImages: {
            ...state.PDFImages,
            ...payload,
          },
        };
      },
    },
  },
  defaultState
);
